import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'en',
  regionISO: 'US',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: '',
  googleAnalyticsId: '',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: 'a914f083-789f-49ca-b729-6b433d3d7d7e',
  footerLogoId: '3dad856b-f2b2-42e3-aece-f80eda5588a6',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Quicksand&display=swap',
  defaultTitle: 'Juegos de Amigas',
  titleTemplate: 'or-site-juegos-de-amigas | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      src: 'https://cdn.cookielaw.org/consent/663f1a13-8c70-431b-a5d4-e16c3f72c731/otSDKStub.js',
      strategy: 'afterInteractive',
      'data-domain-script': '663f1a13-8c70-431b-a5d4-e16c3f72c731'
    },
    {
      id: 'ot-callback-script',
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            function OptanonWrapper() {
            };
          `
      }
    },
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      src: 'https://pm.azerioncircle.com/p/focus/',
      strategy: 'afterInteractive',
    },
  ],
  links: [
  ],
};